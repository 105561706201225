import Styled from 'styled-components';

export const StyledAppContainer = Styled.div`
  min-width: fit-content;
  width: 100%;
  min-height: calc(100% - 50px);
  padding-bottom: 1rem;
`;

export const StyledPageContainer = Styled.div`
  margin: 2.4rem auto;
  padding: 0 3.2rem;
  max-width: 144rem;
  transition: margin-left 300ms;

  @media (max-width: ${props => props.theme.medias.xs.max}px) {
    padding: 0;
    margin: 1.6rem;
    margin-bottom: 2.4rem;
  }

  @media (min-width: ${props => props.theme.medias.l.min}px) {
    margin-left: ${({ isDrawerExpanded }) => (isDrawerExpanded ? '204' : '82')}px;
  }
`;


import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'og-merchant-portal-react-library';

import SessionTimeoutContent from './SessionTimeoutContent';
import CookieLogoutTimeoutContext from "../../../utils/CookieLogoutTimeoutContext";

import getKeycloak from '../../../keycloak';

const SessionTimeout = ({
  sessionTimeoutValue,
  children
}) => {
  const [isLoggedOut, setIsLoggedOut] = React.useState(false);
  const timer = React.useRef(0);
  const {
    cookieLogoutTimeout,
    setCookieLogoutTimeout
  } = React.useContext(CookieLogoutTimeoutContext);

  const logout = React.useCallback(() => {
    clearTimeout(timer.current);
    setIsLoggedOut(true);

    const keycloak = getKeycloak();
    const logoutUrl = `${keycloak.authServerUrl}/realms/${keycloak.realm}/protocol/openid-connect/logout`;
    const data = new URLSearchParams();
    data.append('client_id', keycloak.clientId);
    data.append('refresh_token', keycloak.refreshToken);

    fetch(logoutUrl, {
      credentials: 'include',
      method: 'post',
      body: data,
      headers:
      {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${keycloak.token}`,
      }
    });
  }, []);

  const resetTimer = React.useCallback(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(logout, sessionTimeoutValue * 1000);
  }, [logout, sessionTimeoutValue]);

  React.useEffect(() => {
    timer.current = setTimeout(logout, sessionTimeoutValue * 1000);
    document.addEventListener('click', resetTimer);

    return () => {
      clearTimeout(timer.current);
      document.removeEventListener('click', resetTimer);
    };
  }, [resetTimer, logout, sessionTimeoutValue]);

  React.useEffect(() => {
    if (cookieLogoutTimeout) {
      document.removeEventListener('click', resetTimer);
      clearTimeout(timer.current);
      timer.current = setTimeout(logout, cookieLogoutTimeout * 1000);
      setCookieLogoutTimeout(null);
    }
  }, [cookieLogoutTimeout, logout, resetTimer, setCookieLogoutTimeout]);

  return isLoggedOut ? (
    <Modal
      id="session-timeout-modal"
      onClose={logout}
      showCloseButton={false}
      closeOnOutsideClick={false}
      domIdToAppendModal="modal-root"
    >
      <SessionTimeoutContent />
    </Modal>
  ) : (
    children
  );
};

SessionTimeout.propTypes = {
  sessionTimeoutValue: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired
};

export default SessionTimeout;

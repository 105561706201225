import Styled from 'styled-components';

export const StyledFooter = Styled.div`
  height: 30px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 3.2rem;
  transition: margin-left 300ms;

  @media (max-width: ${props => props.theme.medias.xs.max}px) {
    padding: 0;
    margin: 1.6rem;
    margin-bottom: 2.4rem;
  }

  @media (min-width: ${props => props.theme.medias.l.min}px) {
    margin-left: ${({ isDrawerExpanded }) => (isDrawerExpanded ? '204' : '82')}px;
  }

  @media (max-width: ${props => props.theme.medias.m.max}px) {
    justify-content: center;
    padding-bottom: 1rem;
  }
 `;

export const StyledQuickLink = Styled.a`
  text-decoration: none;
  color:  ${props => props.theme.colors.greyScale.body};
  font-size: 1.4rem;
  line-height: 3rem;
  position: relative;
  
  &:not(:last-child) {
    margin-right: 1.6rem;
  }

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    right: -0.8rem;
    top: 50%;
    transform: translateY(-50%);
    height: 1.2rem;
    width: 1px;
    background-color: ${props => props.theme.colors.greyScale.input};
    border-radius: 2px;
  }

  @media (max-width: ${props => props.theme.medias.m.max}px) {
    font-size: 1.1rem;
  }

  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.colors.primary.regular};
    font-weight: normal;
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.primary.regular};
    outline-offset: 3px;
    font-weight: normal;
    border-radius: 2px;
    text-decoration: underline;
    color: ${props => props.theme.colors.primary.regular};
  }
`;

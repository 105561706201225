/* eslint-disable no-console */
let moduleRoutes = [];

/**
 * Fetches the `module-routes.json` and returns reponse's JSON
 * 
 * @returns {Object} The reponse's JSON
 */
const loadModulesRoutes = () =>
  fetch(`/module-routes.json`, { cache: 'no-cache' })
    .then(response => response.json())
    .catch(err =>
      console.error("An error occured when trying to load the 'modules-routes.json'", err)
    );

/**
 * Gets all the module routes from `module-routes.json` and stores it on the variable `moduleRoutes`
 */
const getBaseRoutes = async () => {
  if (!moduleRoutes || moduleRoutes.length === 0) {
    const jsonResult = await loadModulesRoutes();

    moduleRoutes = jsonResult.routes;

    console.info("Modules base route loaded !");
  }

  return moduleRoutes;
};

export default getBaseRoutes;

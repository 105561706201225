import React from 'react';

const NoAccessLogo = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#E8F4FA"
      stroke="#DAEDF7"
      strokeWidth="1.24"
      d="M10 50a40 40 0 1 0 80 0 40 40 0 0 0-80 0Z"
    />
    <path
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.24"
      d="M47.023 36.011A15.863 15.863 0 0 1 28 51.54"
    />
    <path
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.24"
      d="M28.005 43.62v-7.609a19.018 19.018 0 0 1 19.018-19.018v0a19.017 19.017 0 0 1 19.018 19.018 34.7 34.7 0 0 1-4.119 15.82"
    />
    <path
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.24"
      d="M54.8 49.65c3.388 2 7.397 2.674 11.253 1.891M52.688 52.822a19.562 19.562 0 0 0 10.184 2.841c.947 0 1.892-.066 2.829-.2M50.1 55.69a23.344 23.344 0 0 0 12.768 3.777c.54 0 1.078-.018 1.611-.055M47.023 62.625A31.894 31.894 0 0 1 34.6 66.264M47.023 62.625a31.895 31.895 0 0 0 12.419 3.639M54.565 69.324a31 31 0 0 1-7.542-2.265 30.998 30.998 0 0 1-7.541 2.265M44.487 70.877h5.072"
    />
    <path
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.24"
      d="M31.809 44.039v-8.028A15.214 15.214 0 0 1 47.023 20.8v0a15.215 15.215 0 0 1 15.215 15.211 30.91 30.91 0 0 1-4.261 15.078"
    />
    <path
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.24"
      d="M35.612 42.957v-6.946A11.41 11.41 0 0 1 47.023 24.6v0a11.41 11.41 0 0 1 11.411 11.411c-.334 14.579-10.519 26.33-26.648 27.23"
    />
    <path
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.24"
      d="M47.012 58.219a29.167 29.167 0 0 0 15.226 5.022M28.005 43.62a8.245 8.245 0 0 0 11.411-7.609 7.607 7.607 0 0 1 7.607-7.611v0a7.607 7.607 0 0 1 7.607 7.607 23.458 23.458 0 0 1-25.066 23.405"
    />
    <path
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.24"
      d="M28.057 47.648a12.053 12.053 0 0 0 15.162-11.637 3.8 3.8 0 0 1 7.6 0 19.669 19.669 0 0 1-22.481 19.45"
    />
    <path
      fill="#45413C"
      d="M34.559 81.02c0 .225.355.449 1.045.657.69.208 1.702.397 2.977.556 1.275.16 2.788.286 4.454.372 1.666.087 3.452.131 5.255.131s3.589-.044 5.255-.13a63.029 63.029 0 0 0 4.454-.373c1.275-.159 2.287-.348 2.977-.556.69-.209 1.045-.432 1.045-.657 0-.225-.355-.448-1.045-.657-.69-.208-1.702-.397-2.977-.556a63.029 63.029 0 0 0-4.454-.372c-1.666-.087-3.452-.131-5.255-.131s-3.589.044-5.255.13c-1.665.087-3.18.213-4.454.373-1.275.159-2.287.348-2.977.556-.69.209-1.045.432-1.045.657Z"
    />
    <path
      fill="#DAEDF7"
      d="M82.839 53.761V66.44H78.4V53.761a6.34 6.34 0 0 0-12.679 0V66.44h-4.436V53.761a10.777 10.777 0 1 1 21.554 0Z"
    />
    <path
      fill="#E8F4FA"
      d="M72.062 42.984a10.785 10.785 0 0 0-10.777 10.777V56.3a10.777 10.777 0 1 1 21.554 0v-2.539a10.785 10.785 0 0 0-10.777-10.777Z"
    />
    <path
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.24"
      d="M82.839 53.761V66.44H78.4V53.761a6.34 6.34 0 0 0-12.679 0V66.44h-4.436V53.761a10.777 10.777 0 1 1 21.554 0v0Z"
    />
    <path fill="#FFE500" d="M58.75 58.199h26.625v18.384H58.75V58.199Z" />
    <path
      fill="#656769"
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.24"
      d="M74.6 65.806a2.535 2.535 0 1 0-3.8 2.184v2.887a1.268 1.268 0 0 0 2.536 0V67.99a2.524 2.524 0 0 0 1.264-2.184Z"
    />
    <path
      fill="#FFF48C"
      d="M82.839 58.2H61.285a2.535 2.535 0 0 0-2.535 2.535v3.8A2.536 2.536 0 0 1 61.285 62h21.554a2.536 2.536 0 0 1 2.536 2.536v-3.8a2.535 2.535 0 0 0-2.536-2.536Z"
    />
    <path
      stroke="#45413C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.24"
      d="M58.75 58.199h26.625v18.384H58.75V58.199Z"
    />
  </svg>
);

export default NoAccessLogo;
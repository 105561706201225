/**
 * Receives an URL and extracts the Module name and returns it starting with a slash `/`
 *
 * @param {String} url The URL
 * @returns {String} The Module name starting with a slash `/`
 */
function extractModuleNameAndPrependSlash(url) {
  if (url.includes('/')) {
    return `/${url.split('/')[1]}`;
  }

  return `/${url}`;
}

export default extractModuleNameAndPrependSlash;

const getAllFor = locale => {
  return fetch(`/assets/${locale}.json`, { cache: 'no-cache' }).then(response => response.json());
};

const getAvailableTranslationsList = () => {
  return window.appSettings.supportedLanguages.map(supportedLanguage => {
    const [locale, region] = supportedLanguage.split('-');
    return {
      locale,
      region
    };
  });
};

export default { getAllFor, getAvailableTranslationsList };

import React from 'react';
import PropTypes from 'prop-types';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import nl from 'react-intl/locale-data/nl';
import it from 'react-intl/locale-data/it';
import de from 'react-intl/locale-data/de';
import es from 'react-intl/locale-data/es';

import I18nContext from '../I18nContext';
import translations from '../../../../utils/translationApi';

addLocaleData([...en, ...fr, ...nl, ...it, ...de, ...es]);

export default class I18nProvider extends React.PureComponent {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    translatedMessages: PropTypes.object,
    availableTranslations: PropTypes.arrayOf(PropTypes.shape({locale: PropTypes.string, region: PropTypes.string})),
    children: PropTypes.element.isRequired,
    region: PropTypes.string.isRequired,
  };

  static defaultProps = {
    translatedMessages: {},
    availableTranslations: [{locale: 'en', region: 'gb'}]
  };

  constructor(props) {
    super(props);
    const { locale, translatedMessages, availableTranslations, region} = this.props;
    global.localStorage.setItem('locale', locale);
    global.localStorage.setItem('region', region);
    this.state = {
      locale,
      region,
      translatedMessages,
      availableTranslations, // eslint-disable-line
      changeLocale: this.changeLocale, // eslint-disable-line
      addTranslations: this.addTranslations // eslint-disable-line
    };
  }


  changeLocale = newLocaleWithRegion => {
    const [newLocale, newRegion] = newLocaleWithRegion.split('-');
    global.localStorage.setItem('locale', newLocale);
    global.localStorage.setItem('region', newRegion);

    translations
      .getAllFor(newLocale)
      .then(messages => {
        this.setState(previousState => ({ ...previousState, translatedMessages: {...previousState.translatedMessages, ...messages}, locale: newLocale, region: newRegion }));
      });
  };

  addTranslations = newTranslations => {
    this.setState(prevState => ({
      translatedMessages: {
        ...prevState.translatedMessages,
        ...newTranslations
      }
    }));
  };

  render() {
    const { children } = this.props;
    const { locale, translatedMessages, region } = this.state;

    return (
      <I18nContext.Provider value={this.state}>
        <IntlProvider key={locale} locale={`${locale}-${region}`} messages={translatedMessages}>
          {children}
        </IntlProvider>
      </I18nContext.Provider>
    );
  }
}

import React from 'react';
import { withTheme } from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { TextV2, TextWeight, TextSize } from 'og-merchant-portal-react-library';
import getKeycloak from '../../../keycloak';

import LogoutLogo from './LogoutLogo';
import {
  StyledSessionTimeoutContent,
  StyledTextContainer,
  StyledTitleContainer,
  StyledLogoutContainer,
  StyledAnchor
} from './SessionTimeoutContent.styled';

function SessionTimeoutContent({ theme, intl }) {
  const timeoutTitle = intl.formatMessage({
    id: 'global.sessionTimeout.title',
    defaultMessage: 'Automatically logged out'
  });

  const subtitle = (
    <TextV2 color="secondary" size="subtitle">
      <FormattedMessage id="global.login" defaultMessage="Login" />
    </TextV2>
  );

  return (
    <StyledSessionTimeoutContent>
      <StyledTitleContainer>
        <LogoutLogo />

        <TextV2 weight={TextWeight.semibold} size={TextSize.h5} color={theme.colors.greyScale.body}>
          {timeoutTitle}
        </TextV2>
      </StyledTitleContainer>

      <StyledTextContainer>
        <TextV2 size={TextSize.lg} data-test="global_sessionTimeout_message">
          <FormattedMessage
            id="global.sessionTimeout.message"
            defaultMessage="For security reasons, you are logged out after {time} minutes of inactivity."
            values={{ time: 15 }}
          />
        </TextV2>
      </StyledTextContainer>

      <StyledLogoutContainer>
        <div id="global.sessionTimeout.action">
          <StyledAnchor
            id="logout_button"
            onClick={() => getKeycloak().login()}
            role="button"
            data-test="global_sessionTimeout_action_login"
          >
            {subtitle}
          </StyledAnchor>
        </div>
      </StyledLogoutContainer>
    </StyledSessionTimeoutContent>
  );
}

SessionTimeoutContent.propTypes = {
  /** The theme object */
  theme: PropTypes.object.isRequired,

  /** `I18nProvider`'s object */
  intl: PropTypes.object.isRequired
};

export default React.memo(injectIntl(withTheme(SessionTimeoutContent)));

const defaultRegionForLocale = {
  de: 'de',
  nl: 'nl',
  en: 'gb',
  es: 'es',
  fr: 'fr',
  it: 'it'
};

export default class UserPreferences {
  static getPreSelectedLocaleOrDefault() {
    const preSelectedLanguage = global.localStorage.getItem('locale');
    const preSelectedRegion = global.localStorage.getItem('region');
    const language = global.navigator && global.navigator.language;
    let lang;
    let region;
    if (preSelectedLanguage) {
      lang = preSelectedLanguage;
      region = preSelectedRegion || defaultRegionForLocale[lang] || lang;
    } else if (!language) {
      lang = 'en';
      region = 'gb';
    } else {
      [lang, region] = language.split('-');
    }
    return {
      lang,
      region
    };
  }
}

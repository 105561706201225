import React from 'react';

const i18nDefault = {
  locale: 'en',
  region: 'gb',
  availableTranslations: ['en'],
  translatedMessages: {},
  changeLocale: () => {}
};
const I18nContext = React.createContext(i18nDefault);
export default I18nContext;

import React from "react";
import PropTypes from "prop-types";

const CookieLogoutTimeoutContext = React.createContext({cookieLogoutTimeout: null, setCookieLogoutTimeout: () => {}});

export const CookieLogoutTimeoutProvider = ({children}) => {
  const [cookieLogoutTimeout, setCookieLogoutTimeout] = React.useState(null);

  return (
    <CookieLogoutTimeoutContext.Provider value={{cookieLogoutTimeout, setCookieLogoutTimeout}}>
      {children}
    </CookieLogoutTimeoutContext.Provider>
  );
};

CookieLogoutTimeoutProvider.propTypes = {
  /** The children to render */
  children: PropTypes.node.isRequired
};

export default CookieLogoutTimeoutContext;

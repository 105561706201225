import React, { useContext } from 'react';
import { Dropdown, DropdownPanelPosition, DropdownVariant } from '@worldline/gaia';

import locales from '../../common/internationalization/locales';
import I18nContext from '../../common/internationalization/I18nContext';


const LanguageSelector = () => {
  const { availableTranslations, locale, changeLocale, region } = useContext(I18nContext);

  const [options, setOptions] = React.useState([]);

  const selectedLocale = {
    value: `${locale.toUpperCase()}-${region.toUpperCase()}`,
    label: locales[`${locale.toUpperCase()}-${region.toUpperCase()}`]
  };
  const availableLocalesAndTranslations =
    Object.keys(locales).length !== 0 && availableTranslations.length > 1;

  const onOptionChange = value => {
    if (locale !== value) {
      changeLocale(value.toLowerCase());
    }
  };

  React.useEffect(() => {
    setOptions(
      availableTranslations.map(language => {
        const mappedLanguage = `${language.locale.toUpperCase()}-${language.region.toUpperCase()}`;
        return {
          value: mappedLanguage,
          label: locales[mappedLanguage]
        };
      })
    );
  }, [availableTranslations]);

  if (availableLocalesAndTranslations) {
    return (
      <Dropdown
        title={selectedLocale.label}
        defaultSelectedValue={selectedLocale.value}
        options={options}
        onChange={onOptionChange}
        variant={DropdownVariant.DropdownWithoutBorder}
        dataTest="global_menu_item_language_selector"
        panelPosition={DropdownPanelPosition.right}
        displayValueOnHeader={false}
      />
    );
  }

  return null;
};

export default LanguageSelector;

import getKeycloak from '../../keycloak';

const httpClient = () => {
  httpClient.getSetting = name => {
    const { appSettings } = window;
    if (!appSettings) {
      throw new Error('app settings are not loaded. Please check your index file');
    }
    const setting = appSettings[name];
    if (setting === null || setting === undefined) {
      throw new Error(
        `${name} is not defined in your settings. Please check the appSettings file.`
      );
    }
    return setting;
  };

  httpClient.handleErrors = response => {
    return new Promise((resolve, reject) => {
      if (response.ok) {
        resolve(response);
      } else {
        response.json().then(
          resp => {
            reject(resp);
          },
          () => {
            reject(response);
          }
        );
      }
    });
  };

  httpClient.redirectWhenNotAuthenticated = response => {
    if (response.status === 401) {
      httpClient.redirectToLogin();
    }

    return response;
  };

  httpClient.redirectToLogin = () => {
    return getKeycloak().login();
  };

  httpClient.refreshToken = () => {
    return getKeycloak().updateToken(270).catch(httpClient.redirectToLogin);
  };

  httpClient.parseContentDispositionHeaders = response => {
    const contentDispositionHeaders = {};
    const contentDispositionHeaderString = response.headers.get('content-disposition');

    if (contentDispositionHeaderString !== null) {
      contentDispositionHeaderString.split(';').forEach(rawHeader => {
        const [key, value] = rawHeader.trim().split('=');
        if (value !== undefined) contentDispositionHeaders[key] = value.trim().replaceAll('"', '');
      });
    }

    return new Promise((resolve, reject) => {
      if (response.ok) {
        return resolve({
          rawBlob: response.blob(),
          contentDispositionHeaders
        });
      }

      return reject(new Error(response.statusText));
    });
  };

  httpClient.get = (url, tenant) => {
    const baseUrl = httpClient.getSetting('apiBaseUrl');
    return httpClient
      .refreshToken()
      .then(() =>
        fetch(baseUrl + url, {
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${getKeycloak().token}`,
            'X-Rbmp-Tenant': tenant
          }
        })
      )
      .then(httpClient.redirectWhenNotAuthenticated)
      .then(httpClient.handleErrors)
      .then(response => response.json());
  };

  httpClient.post = (url, data, tenant) => {
    const baseUrl = httpClient.getSetting('apiBaseUrl');
    return httpClient
      .refreshToken()
      .then(() =>
        fetch(baseUrl + url, {
          credentials: 'include',
          method: 'post',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getKeycloak().token}`,
            'X-Rbmp-Tenant': tenant
          }
        })
      )
      .then(httpClient.redirectWhenNotAuthenticated)
      .then(httpClient.handleErrors)
      .then(res => res.text())
      .then(text => (text.length ? JSON.parse(text) : null));
  };

  httpClient.delete = (url, tenant) => {
    const baseUrl = httpClient.getSetting('apiBaseUrl');
    return httpClient
      .refreshToken()
      .then(() =>
        fetch(baseUrl + url, {
          credentials: 'include',
          method: 'delete',
          headers: {
            Authorization: `Bearer ${getKeycloak().token}`,
            'X-Rbmp-Tenant': tenant
          }
        })
      )
      .then(httpClient.redirectWhenNotAuthenticated)
      .then(httpClient.handleErrors)
      .then(r => r.text());
  };

  httpClient.download = (url, data, tenant) => {
    const baseUrl = httpClient.getSetting('apiBaseUrl');
    return httpClient
      .refreshToken()
      .then(() =>
        fetch(baseUrl + url, 
          data ? {
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${getKeycloak().token}`,
              'X-Rbmp-Tenant': tenant
            }
          } : {
            credentials: 'include',
            method: 'GET',
            headers: {
              Authorization: `Bearer ${getKeycloak().token}`,
              'X-Rbmp-Tenant': tenant
            }
          }
        )
      )
      .then(httpClient.redirectWhenNotAuthenticated)
      .then(httpClient.handleErrors)
      .then(httpClient.parseContentDispositionHeaders)
      .then(({ contentDispositionHeaders, rawBlob }) => {
        rawBlob.then(blob => {
          const blobUrl = window.URL.createObjectURL(blob);

          // create a link to download the file
          const a = document.createElement('a');
          a.href = blobUrl;
          a.id = 'temporary-download-link';
          if (contentDispositionHeaders && contentDispositionHeaders.filename)
            a.download = contentDispositionHeaders.filename;
          document.body.appendChild(a);
          a.click();

          // cleanup
          a.remove();
          window.URL.revokeObjectURL(blobUrl);
        });
      });
  };

  return httpClient;
};

export default httpClient();

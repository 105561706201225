import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { Row, TextV2 } from 'og-merchant-portal-react-library';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, ButtonKind } from '@worldline/gaia';

import NotFoundLogo from './NotFoundLogo';

const StyledTitleContainer = styled.div`
  margin-bottom: 1.2rem;
  span {
    color: ${props => props.theme.colors.greyScale.body};
  }
`;

const StyledSubTitleContainer = styled.div`
  margin-bottom: 2.8rem;
  span {
    color: ${props => props.theme.colors.greyScale.subText};
  }
`;

const StyledContainer = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledLogoContainer = styled.div`
  margin-bottom: 2rem;
`;

export const PageNotFoundCustom = ({ history, theme, intl }) => {
  const title = intl.formatMessage({
    id: 'global.pageNotFound.title',
    defaultMessage: '404 error page'
  });

  const subTitle = intl.formatMessage({
    id: 'global.pageNotFound.subTitle',
    defaultMessage: 'The page that you are looking for could not be found'
  });

  const handleClick = e => {
    e.preventDefault();
    history.push('/');
  };
  return (
    <StyledContainer>
      <StyledLogoContainer>
        <NotFoundLogo />
      </StyledLogoContainer>
      <StyledTitleContainer>
        <TextV2 weight="semibold" color={theme.colors.greyScale.body} size="h5">
          {title}
        </TextV2>
      </StyledTitleContainer>

      <StyledSubTitleContainer>
        <TextV2 weight="regular" size="lg">
          {subTitle}
        </TextV2>
      </StyledSubTitleContainer>

      <Row justifyContent="center">
        <Button kind={ButtonKind.primary} onClick={handleClick}>
          <FormattedMessage id="global.pageNotFound.url" defaultMessage="Back to home page" />
        </Button>
      </Row>
    </StyledContainer>
  );
};

PageNotFoundCustom.propTypes = {
  history: PropTypes.object.isRequired,

  /** The theme object from our custom `ThemeProvider` */
  theme: PropTypes.object.isRequired,

  /** `I18nProvider`'s object */
  intl: PropTypes.object.isRequired
};

export default withRouter(injectIntl(withTheme(PageNotFoundCustom)));

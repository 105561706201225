import React from 'react';

const NotFoundLogo = () => (
  <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.8 64a51.202 51.202 0 0 0 87.405 36.205 51.2 51.2 0 1 0-87.404-36.204Z"
      fill="#E8F4FA"
      stroke="#DAEDF7"
      strokeWidth="1.28"
    />
    <path
      opacity=".15"
      d="M47.008 104.928c0 .509 1.618.997 4.499 1.357 2.88.361 6.787.563 10.86.563 4.075 0 7.981-.202 10.862-.563 2.88-.36 4.499-.848 4.499-1.357s-1.618-.998-4.499-1.358c-2.88-.36-6.787-.562-10.861-.562-4.074 0-7.98.202-10.861.562-2.88.36-4.5.849-4.5 1.358Z"
      fill="#45413C"
    />
    <path
      d="M43.01 93.002a16.948 16.948 0 0 1 0-23.948l4.095-4.096a1.106 1.106 0 0 1 1.833.432l.583 1.747a1.407 1.407 0 0 0 .896.896l1.615.54a.707.707 0 0 1 .277 1.168L48 74.041a9.88 9.88 0 1 0 13.973 13.972l4.992-4.992a9.89 9.89 0 0 0 0-13.97 9.776 9.776 0 0 0-1.256-1.061.705.705 0 0 1 .186-1.244l1.399-.466a1.407 1.407 0 0 0 .896-.896l.503-1.507a1.484 1.484 0 0 1 1.773-.968c.216.056.415.161.583.306a16.947 16.947 0 0 1 .909 24.792L66.967 93a16.948 16.948 0 0 1-23.958.002Z"
      fill="#fff"
    />
    <path
      d="m72.88 83.942-4.993 4.992a16.93 16.93 0 0 1-28.815-13.696 16.934 16.934 0 0 0 27.89 17.764l4.992-4.992a16.864 16.864 0 0 0 4.864-10.254 16.824 16.824 0 0 1-3.939 6.186Z"
      fill="#DAEDF7"
    />
    <path
      d="M102.261 51.066A16.946 16.946 0 0 1 85.327 68h-5.794a1.107 1.107 0 0 1-.991-1.602l.819-1.648a1.408 1.408 0 0 0 0-1.262l-.762-1.523a.706.706 0 0 1 .631-1.024h6.092a9.88 9.88 0 0 0 0-19.757H78.27a9.89 9.89 0 0 0-9.877 9.88c0 .548.046 1.096.138 1.636a.706.706 0 0 1-1.011.75l-1.319-.66a1.416 1.416 0 0 0-1.263 0l-1.418.71a1.492 1.492 0 0 1-1.493-.092 1.453 1.453 0 0 1-.64-1.103 16.947 16.947 0 0 1 16.887-18.176h7.057a16.948 16.948 0 0 1 16.93 16.937Z"
      fill="#fff"
    />
    <path
      d="M85.327 41.187a9.89 9.89 0 0 1 9.856 10.529 9.877 9.877 0 0 0-8.84-14.286h-7.055a9.893 9.893 0 0 0-9.856 9.23 9.893 9.893 0 0 1 8.84-5.473h7.055ZM86.342 64.243h-6.834a1.399 1.399 0 0 1-.143.512l-.824 1.648a1.109 1.109 0 0 0 .99 1.602h5.795a16.956 16.956 0 0 0 16.748-14.423 16.96 16.96 0 0 1-15.732 10.661Z"
      fill="#DAEDF7"
    />
    <path
      d="M39.535 53.54a.716.716 0 0 1 .725 1.163l-7.658 8.704a.717.717 0 0 1-1.144-.093L27.93 57.66a.715.715 0 0 1 .419-1.069l11.184-3.05ZM42.457 46.194 26.869 40.01a1.036 1.036 0 0 1-.43-1.61l5.977-7.558a1.037 1.037 0 0 1 1.664.048l9.61 13.742a1.035 1.035 0 0 1-1.233 1.56ZM50.661 36.992l-1.476-11.581a.72.72 0 0 1 .834-.802l6.617 1.1a.72.72 0 0 1 .529 1.024l-5.142 10.48a.721.721 0 0 1-1.362-.22Z"
      fill="#FFE500"
      stroke="#B58B00"
      strokeWidth="1.28"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M102.261 51.066A16.946 16.946 0 0 1 85.327 68h-5.794a1.107 1.107 0 0 1-.991-1.602l.819-1.648a1.408 1.408 0 0 0 0-1.262l-.762-1.523a.706.706 0 0 1 .631-1.024h6.092a9.88 9.88 0 0 0 0-19.757H78.27a9.89 9.89 0 0 0-9.877 9.88c0 .548.046 1.096.138 1.636a.706.706 0 0 1-1.011.75l-1.319-.66a1.416 1.416 0 0 0-1.263 0l-1.418.71a1.492 1.492 0 0 1-1.493-.092 1.453 1.453 0 0 1-.64-1.103 16.947 16.947 0 0 1 16.887-18.176h7.057a16.948 16.948 0 0 1 16.93 16.937v0ZM43.01 93.002a16.948 16.948 0 0 1 0-23.948l4.095-4.096a1.106 1.106 0 0 1 1.833.432l.583 1.747a1.407 1.407 0 0 0 .896.896l1.615.54a.707.707 0 0 1 .277 1.168L48 74.041a9.88 9.88 0 1 0 13.973 13.972l4.992-4.992a9.89 9.89 0 0 0 0-13.97 9.776 9.776 0 0 0-1.256-1.061.705.705 0 0 1 .186-1.244l1.399-.466a1.407 1.407 0 0 0 .896-.896l.503-1.507a1.484 1.484 0 0 1 1.773-.968c.216.056.415.161.583.306a16.947 16.947 0 0 1 .909 24.792L66.967 93a16.948 16.948 0 0 1-23.958.002v0Z"
      stroke="#45413C"
      strokeWidth="1.28"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NotFoundLogo;

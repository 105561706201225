import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Text } from 'og-merchant-portal-react-library';

const FloatingButton = styled.div`
  position: fixed;
  bottom: 0;
  right: 1.6em;
  z-index: 10;

  path {
    stroke: ${props => props.theme.colors.greyScale.white};
    stroke-width: 1px;
  }

  .desktop-only {
    display: none;
    transform: rotate(90deg) translateZ(0);
    margin-right: 1rem;
  }
  .mobile-only {
    display: inherit;
    margin-left: 1em;
  }
  @media (-ms-high-contrast: none) and (max-width: ${props => props.theme.medias.xs.max}px) {
    .desktop-only {
      display: inherit;
      opacity: 0;
      position: absolute;
      z-index: -99;
    }
  }

  @media (min-width: ${props => props.theme.medias.s.min}px) {
    top: 0;
    bottom: 0;
    margin: auto;
    height: 5rem;
    height: -webkit-fit-content;
    transform: rotate(-90deg) translateZ(0);
    transform-origin: calc(100% - 2.6em) 0;

    .desktop-only {
      display: inherit;
    }
    .mobile-only {
      display: none;
    }
  }

  a {
    display: inline-block;
    min-width: 6.5em;
    padding: 0.75em;
    text-align: center;
    background-color: #d6006b;
    text-decoration: none;
    transition: 0.2s all;
    cursor: pointer;
    box-shadow: 0px 3px 20px ${props => props.theme.shadows.primary};
    border-radius: 6px 6px 0 0;
    svg {
      vertical-align: middle;
    }
  }

  a:hover {
    background: #e60073;
  }
`;

const FeedbackButton = ({ url }) => {
  if (url) {
    return (
      <FloatingButton data-test="global_feedback_button">
        <FormattedMessage id="global.feedback.title" defaultMessage="Open in a new tab">
          {title => (
            <a
              data-test="global_feedback_button_link"
              href={url}
              title={title}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                className="desktop-only"
                width="17"
                height="18"
                viewBox="0 0 22 23"
                fill="none"
                shapeRendering="geometricPrecision"
              >
                <mask id="path-1-inside-1" fill="white">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.49646 1.48242C2.2973 1.48242 1.3252 2.45453 1.3252 3.65369V13.4244C1.3252 14.6235 2.2973 15.5956 3.49646 15.5956H12.6555L13.4148 21.6462L17.4288 15.5956H18.6953C19.8945 15.5956 20.8666 14.6235 20.8666 13.4244V3.65369C20.8666 2.45453 19.8945 1.48242 18.6953 1.48242H3.49646Z"
                  />
                </mask>
                <path
                  d="M12.6555 15.5956L13.6478 15.4711L13.5379 14.5956H12.6555V15.5956ZM13.4148 21.6462L12.4226 21.7707L12.7582 24.4449L14.2481 22.199L13.4148 21.6462ZM17.4288 15.5956V14.5956H16.8922L16.5955 15.0428L17.4288 15.5956ZM2.3252 3.65369C2.3252 3.00682 2.84959 2.48242 3.49646 2.48242V0.482422C1.74502 0.482422 0.325195 1.90225 0.325195 3.65369H2.3252ZM2.3252 13.4244V3.65369H0.325195V13.4244H2.3252ZM3.49646 14.5956C2.84959 14.5956 2.3252 14.0713 2.3252 13.4244H0.325195C0.325195 15.1758 1.74502 16.5956 3.49646 16.5956V14.5956ZM12.6555 14.5956H3.49646V16.5956H12.6555V14.5956ZM14.4071 21.5217L13.6478 15.4711L11.6633 15.7202L12.4226 21.7707L14.4071 21.5217ZM16.5955 15.0428L12.5815 21.0934L14.2481 22.199L18.2621 16.1485L16.5955 15.0428ZM18.6953 14.5956H17.4288V16.5956H18.6953V14.5956ZM19.8666 13.4244C19.8666 14.0713 19.3422 14.5956 18.6953 14.5956V16.5956C20.4468 16.5956 21.8666 15.1758 21.8666 13.4244H19.8666ZM19.8666 3.65369V13.4244H21.8666V3.65369H19.8666ZM18.6953 2.48242C19.3422 2.48242 19.8666 3.00682 19.8666 3.65369H21.8666C21.8666 1.90225 20.4468 0.482422 18.6953 0.482422V2.48242ZM3.49646 2.48242H18.6953V0.482422H3.49646V2.48242Z"
                  fill="white"
                  mask="url(#path-1-inside-1)"
                />
              </svg>
              <Text face="medium" color="secondary">
                <FormattedMessage id="global.feedback.label" defaultMessage="Feedback" />
              </Text>
              <svg
                className="mobile-only"
                width="17"
                height="18"
                viewBox="0 0 22 23"
                fill="none"
                shapeRendering="geometricPrecision"
              >
                <mask id="path-1-inside-1" fill="white">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.49646 1.48242C2.2973 1.48242 1.3252 2.45453 1.3252 3.65369V13.4244C1.3252 14.6235 2.2973 15.5956 3.49646 15.5956H12.6555L13.4148 21.6462L17.4288 15.5956H18.6953C19.8945 15.5956 20.8666 14.6235 20.8666 13.4244V3.65369C20.8666 2.45453 19.8945 1.48242 18.6953 1.48242H3.49646Z"
                  />
                </mask>
                <path
                  d="M12.6555 15.5956L13.6478 15.4711L13.5379 14.5956H12.6555V15.5956ZM13.4148 21.6462L12.4226 21.7707L12.7582 24.4449L14.2481 22.199L13.4148 21.6462ZM17.4288 15.5956V14.5956H16.8922L16.5955 15.0428L17.4288 15.5956ZM2.3252 3.65369C2.3252 3.00682 2.84959 2.48242 3.49646 2.48242V0.482422C1.74502 0.482422 0.325195 1.90225 0.325195 3.65369H2.3252ZM2.3252 13.4244V3.65369H0.325195V13.4244H2.3252ZM3.49646 14.5956C2.84959 14.5956 2.3252 14.0713 2.3252 13.4244H0.325195C0.325195 15.1758 1.74502 16.5956 3.49646 16.5956V14.5956ZM12.6555 14.5956H3.49646V16.5956H12.6555V14.5956ZM14.4071 21.5217L13.6478 15.4711L11.6633 15.7202L12.4226 21.7707L14.4071 21.5217ZM16.5955 15.0428L12.5815 21.0934L14.2481 22.199L18.2621 16.1485L16.5955 15.0428ZM18.6953 14.5956H17.4288V16.5956H18.6953V14.5956ZM19.8666 13.4244C19.8666 14.0713 19.3422 14.5956 18.6953 14.5956V16.5956C20.4468 16.5956 21.8666 15.1758 21.8666 13.4244H19.8666ZM19.8666 3.65369V13.4244H21.8666V3.65369H19.8666ZM18.6953 2.48242C19.3422 2.48242 19.8666 3.00682 19.8666 3.65369H21.8666C21.8666 1.90225 20.4468 0.482422 18.6953 0.482422V2.48242ZM3.49646 2.48242H18.6953V0.482422H3.49646V2.48242Z"
                  fill="white"
                  mask="url(#path-1-inside-1)"
                />
              </svg>
            </a>
          )}
        </FormattedMessage>
      </FloatingButton>
    );
  }

  return null;
};

FeedbackButton.propTypes = {
  url: PropTypes.string
};

FeedbackButton.defaultProps = {
  url: ""
};

export default FeedbackButton;

import Styled from 'styled-components';

export const StyledSessionTimeoutContent = Styled.div`
  padding: 1.6rem 5.6rem;
`;

export const StyledTitleContainer = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.8rem;
  gap: 2.4rem;
  text-align: center;
`;

export const StyledTextContainer = Styled.div`
  display: flex;
  justify-content: center;

  & span {
    max-width: 75%;
    text-align: center;
  }
`;

export const StyledLogoutContainer = Styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2.4rem;
`;

export const StyledAnchor = Styled.a`
  background-color: ${props => props.theme.colors.primary.regular};
  padding: 1.6rem 2.4rem 1.2rem 2.4rem;
  border-radius: 0.4rem;
  font-weight: ${props => props.theme.font.weights.medium};
  text-decoration: none;
  cursor: pointer;
`;
